import { useQuery } from '@apollo/client';
import classNames from 'classnames';
import { FC, MouseEvent, useEffect, useMemo } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import Divider from '~/components/Divider';
import { PATH } from '~/constants/enum';
import { QUERY_GET_ACTUAL_PRODUCT } from '~/data/home';
import { Product } from '~/services/product';
import { UserAtom } from '~/services/user';
import { WishListAtom } from '~/services/wishlist';
import { getConfig, getUrlCustom } from '~/utils/theme';
import { transformProductItem } from '~/utils/transform';
import ProductImage, { Props as ProductImageProps } from './ProductImage';
import styles from './ProductItem.module.scss';
import ProductName, { Props as ProductNameProps } from './ProductName';
import ProductPrice from './ProductPrice';

export type ProductItemSize = 'large' | 'medium' | 'small' | 'xsmall' | 'smaller';
export enum DisplayInfo {
    Price = 'price',
    Rating = 'rating',
    Wishlist = 'wishlist',
    Compare = 'compare',
    QuickView = 'quickView',
    AddToCart = 'addToCart',
    Title = 'title'
}
export interface ProductItemProps {
    size?: ProductItemSize;
    data: Partial<Product>;
    search?: string;
    onProductClick?: (product: Partial<Product>) => void;
    params?: any;
    loading?: boolean;
    className?: string;
    rootClassName?: string;
    onRemove?: (data: Partial<Product>) => void;
    actions?: (data: Partial<Product>) => JSX.Element;
    type?: 'vertical' | 'horizontal';
    imageProps?: Partial<ProductImageProps>;
    nameProps?: Partial<ProductNameProps>;
    onCompleted?: (data: Product, clientOptions: any) => void;
    displayInfo?: DisplayInfo[] | any[];
}

const ProductItem: FC<ProductItemProps> = ({
    size = 'medium',
    data: optimizeData,
    search,
    onProductClick,
    onRemove,
    params = {},
    loading: productItemLoading,
    className = '',
    rootClassName,
    actions,
    type = 'vertical',
    imageProps,
    nameProps,
    onCompleted,
    displayInfo = [DisplayInfo.Title, DisplayInfo.Price]
}) => {
    const themeConfig = getConfig('ProductItem');
    const currentUser = useRecoilValue(UserAtom.currentUser);
    const setWishList = useSetRecoilState(WishListAtom.wishlist);
    const { wishlistItems, id } = useRecoilValue(WishListAtom.WishlistSelector);
    const productWishlist = wishlistItems?.find((wishlistItem) => wishlistItem?.product?.sku === optimizeData?.sku);

    const {
        data: actualData,
        loading,
        called
    } = useQuery(QUERY_GET_ACTUAL_PRODUCT, {
        // nextFetchPolicy: "no-cache",
        onError: ({ message }) => {
            console.error(optimizeData.sku);
        },
        onCompleted(data) {
            onCompleted?.(data?.products?.items?.[0], { variables: { sku: optimizeData.sku } });
        },
        context: { fetchOptions: { method: 'GET' } },
        variables: {
            sku: optimizeData.sku
        },
        skip: !optimizeData?.sku
    });

    const productActual = transformProductItem(actualData?.products.items?.[0] || ({} as any));

    const data: Partial<Product> = useMemo(() => {
        if (!productActual || loading) return optimizeData || {};
        // return optimizeData;
        return {
            ...(optimizeData || {}),
            ...productActual,
            images: !!optimizeData?.images?.length
                ? optimizeData?.images
                : !!productActual?.media_gallery?.length
                ? productActual?.media_gallery?.map((item: any) => item.url)
                : [optimizeData?.image?.url]
        };
    }, [optimizeData, productActual, loading]);

    const skeletonLoading = productItemLoading || loading;
    const href =
        data.slug &&
        !loading &&
        getUrlCustom({
            pathname: PATH.ProductDetail,
            query: {
                slug: data.slug,
                variant: data.variants?.[0]?.product?.id
            }
        });
    const handleRemove = (e: MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
        e.preventDefault();

        onRemove?.(data);
        // setIsFavorite(!isFavorite);
    };
    const handleAction = (e: MouseEvent<HTMLDivElement>) => {
        actions?.(data);
    };
    const handleClickProduct = () => {
        if (!data.sku || !data.slug) return;
        localStorage.setItem(`${data.sku}`, JSON.stringify(params));
        onProductClick?.(data);
    };

    useEffect(() => {
        if (optimizeData.sku && !!called && !actualData?.products?.items?.length && !skeletonLoading) {
        }
    }, [optimizeData.sku && !!called && !actualData?.products?.items?.length && !skeletonLoading]);

    if (!!optimizeData.sku && !!called && !actualData?.products?.items?.length && !skeletonLoading) {
        return null;
    }

    return (
        <div
            className={classNames('flex flex-col product-item gap-2', styles.product_item, className, {
                ['!flex-row']: type === 'horizontal'
            })}
            key={data.slug}
            data-sku={data.sku}
            onClick={handleClickProduct}
        >
            <div
                className={classNames(
                    styles.product_item_hover,
                    ' relative aspect-square',
                    {
                        'max-w-40 lg:max-w-[50%] flex-1': type === 'horizontal'
                    },
                    imageProps?.className || ''
                )}
            >
                <ProductImage
                    href={href || ''}
                    data={data as Product}
                    loading={skeletonLoading}
                    onRemove={onRemove && handleRemove}
                    {...(imageProps || {})}
                />
            </div>
            <div
                className={classNames({
                    'flex-1': type === 'horizontal',
                    hidden: !displayInfo.includes(DisplayInfo.Price) && !displayInfo.includes(DisplayInfo.Title)
                })}
            >
                <ProductName
                    href={href || ''}
                    loading={skeletonLoading}
                    name={data?.name}
                    search={search}
                    hidden={!displayInfo.includes(DisplayInfo.Title)}
                    {...(nameProps || {})}
                />
                {themeConfig.showDivider && <Divider dividerColor="#3E3E3E" />}
                <ProductPrice data={data} loading={skeletonLoading} hidden={!displayInfo.includes(DisplayInfo.Price)} />
                {/* <Star value={data.rating_summary} size={16} /> */}
            </div>

            {actions && <div onClick={handleAction}>{actions(data)} </div>}
        </div>
    );
};

export default ProductItem;
